<template>
  <Modal
    ref="modal"
    :bg="false"
    overlay="light"
    style="padding: 1rem"
    :non-closable="nonClosable"
    no-click-away
    :x-style="{
      display: 'grid',
      gap: '3rem',
    }"
    @hide="$emit('hide')"
    @show="$emit('show')"
    @close="$emit('close')"
  >
    <AppNavButton
      v-if="!nonClosable"
      icon="close"
      style="position: fixed; top: 1rem; right: 1rem"
      @click="() => $emit('close') && $refs.modal.hide()"
    />
    <slot></slot>
    <div
      v-if="title && text"
      class="container-xs"
      style="
        display: grid;
        gap: 2rem;
        align-content: center;
        text-align: center;
        padding: 0 2rem;
      "
      :style="{
        alignSelf: nonClosable ? 'flex-end' : 'flex-start',
      }"
    >
      <p
        v-if="title"
        style="font-size: 36px; color: #000; font-family: GTSuper"
      >
        {{ title }}
      </p>
      <p v-if="text" style="font-size: 14px; color: #333">
        {{ text }}
      </p>
    </div>
    <XButton
      v-if="cta"
      type="large"
      style="align-self: flex-end; width: 100%"
      @click="$emit('cta')"
      >{{ cta }}</XButton
    >
  </Modal>
</template>

<script>
import Modal from '../../components/utils/Modal.vue'
import AppNavButton from '../AppNavButton.vue'
import XButton from '../../components/common/global/XButton.vue'

export default {
  name: 'InfoModalFullScreen',
  components: { Modal, AppNavButton, XButton },
  props: { title: String, text: String, cta: String, nonClosable: Boolean },
  methods: {
    show() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
  },
}
</script>

<style scoped lang="scss"></style>
