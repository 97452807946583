<template>
  <div class="container-xs app-grid-large">
    <div class="app-grid-small">
      <AppHeader close-to="/home" title="Login" />
      <router-link class="app-link" to="/signup">Or Join Elude</router-link>
    </div>
    <form class="app-grid-medium" @keydown.enter="loginAction">
      <div>
        <XLabel tag="label" for="email">{{ $t('form.email') }}</XLabel>
        <FormInput
          id="email"
          ref="emailField"
          v-model="forms.login.email"
          type="email"
          required
          name="email"
          :validator="value => (value ? true : $t('form.email-required'))"
          @valid="forms.vset.login.email"
        />
      </div>
      <div>
        <div style="display: flex">
          <XLabel tag="label" for="password">{{ $t('form.password') }}</XLabel>
          <router-link
            to="/account-recovery"
            class="app-link"
            style="margin-left: auto; text-decoration: none"
          >
            {{ $t('login.forgot-password') }}
          </router-link>
        </div>
        <FormInput
          id="password"
          v-model="forms.login.password"
          type="password"
          required
          name="password"
          :validator="value => (value ? true : $t('form.password-required'))"
          @valid="forms.vset.login.password"
        />
        <Transition name="dropdown-s">
          <p v-if="error.show" class="app-info-msg" data-error>
            {{ error.message }}
          </p>
        </Transition>
      </div>
    </form>

    <XButton
      id="submit-login"
      type="main"
      style="margin-top: auto"
      :disabled="!forms.vget.login.form"
      :is-loading="isLoading"
      @click="loginAction"
    >
      LOG IN
    </XButton>

    <div class="app-grid-medium">
      <AuthBreak style="margin-top: 2rem" />
      <AuthSocials />
    </div>

    <InfoModalFullScreen ref="confirmEmail">
      <div
        class="container-xs"
        style="display: grid; justify-items: center; text-align: center"
      >
        <p style="font-size: 32px; font-family: GTSuper">{{ error.title }}</p>
        <p style="margin-top: 1rem">{{ error.message }}</p>
        <a href="mailto:" target="_blank">
          <x-button style="margin-top: 1.5rem" type="large"
            >Go to email</x-button
          >
        </a>
      </div>
    </InfoModalFullScreen>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AppHeader from '../../components-rf/AppHeader.vue'
import AuthBreak from '../../components-rf/auth/AuthBreak.vue'
import AuthSocials from '../../components-rf/auth/AuthSocials.vue'
import InfoModalFullScreen from '../../components-rf/modals/InfoModalFullScreen.vue'
import XButton from '../../components/common/global/XButton.vue'
import XLabel from '../../components/common/global/XLabel.vue'
import FormInput from '../../components/utils/FormInput.vue'
import { appAnalytics } from '../../services/analytics/analytics'

export default {
  name: 'Login',
  components: {
    InfoModalFullScreen,
    AuthBreak,
    AppHeader,
    XButton,
    XLabel,
    FormInput,
    AuthSocials,
  },
  data() {
    return {
      isLoading: false,
      error: {
        show: false,
        message: '',
        title: '',
      },
      showError: false,
      errorMessage: '',
      forms: {
        login: {
          email: '',
          password: '',
        },
      },
    }
  },
  mounted() {
    appAnalytics.page({ title: 'login_screen_view' })
    this.$nextTick(() => {
      this.$refs.emailField.$el.focus()
    })
  },
  methods: {
    ...mapActions('AuthModule', ['login']),
    loginAction() {
      if (!this.forms.vget.login.form) return
      this.isLoading = true
      this.login(this.forms.login)
        .then(() => (this.showError = false))
        .catch(err => {
          const [title, message, type] = err.message.split('|')
          this.error.message = message
          this.error.title = title
          if (type === 'user not verified') {
            this.error.show = false
            this.$refs.confirmEmail.show()
          } else this.error.show = true
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style scoped lang="scss">
input {
  margin-top: 0.5rem;
}
</style>
