<template>
  <div class="auth-break">
    <div></div>
    <p style="font-size: 13px; font-family: LuciferSans">or</p>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'AuthBreak',
}
</script>

<style scoped lang="scss">
.auth-break {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  color: #444444;
  gap: 10px;
  & > div {
    top: 2px;
    position: relative;
    height: 1px;
    background: #444444;
  }
}
</style>
