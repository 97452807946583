<template>
  <div class="app-grid-medium">
    <button
      style="background: none; color: black"
      @click="socialLogin(SOCIAL_PROVIDERS.google.id)"
    >
      <IconGoogle />
      <p>Sign in with Google</p>
    </button>
    <button
      style="background: black; color: white"
      @click="socialLogin(SOCIAL_PROVIDERS.apple.id)"
    >
      <IconApple />
      <p>Continue with Apple</p>
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { SOCIAL_PROVIDERS } from '../../constants/AUTH_PROVIDERS'
import IconApple from '../icons/socials/IconApple.vue'
import IconGoogle from '../icons/socials/IconGoogleColorful.vue'

export default {
  name: 'AuthSocials',
  components: { IconGoogle, IconApple },
  data() {
    return {
      SOCIAL_PROVIDERS,
    }
  },
  methods: mapActions('AuthModule', ['socialLogin']),
}
</script>

<style scoped lang="scss">
button {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
p {
  font-family: SFPro;
  font-size: 17px;
  font-weight: 600;
  margin-left: 8px;
}
</style>
